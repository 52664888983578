body {
  margin: 0;
  font-family: 'Graphik LCG', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: black;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

@media (min-width: 150px) {
    .App {
        font-size: calc(10px + 10vmin);
    }
    .ampm {
        font-size: calc(10px + 5vmin);
    }
}

@media (min-width: 375px) {
    .App {
        font-size: calc(10px + 20vmin);
    }
    .ampm {
        font-size: calc(10px + 10vmin);
    }
}

@media (min-width: 1000px) {
    .App {
        font-size: calc(10px + 30vmin);
    }
    .ampm {
        font-size: calc(10px + 15vmin);
    }
}

@media (min-width: 1300px) {
    .App {
        font-size: calc(10px + 40vmin);
    }
    .ampm {
        font-size: calc(10px + 20vmin);
    }
}

@media (min-width: 1600px) {
    .App {
        font-size: calc(10px + 50vmin);
    }
    .ampm {
        font-size: calc(10px + 25vmin);
    }
}

@media (min-width: 2000px) {
    .App {
        font-size: calc(10px + 60vmin);
    }
    .ampm {
        font-size: calc(10px + 30vmin);
    }
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
